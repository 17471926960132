import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import { Typography } from '@material-ui/core';
import { getAnalytics, logEvent  } from "firebase/analytics";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Help(props) {
  const classes = useStyles();
  const analytics = getAnalytics();
  logEvent(analytics, 'help.viewed');
  props.setTitle("Help");
  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <Title>Get in touch</Title>
                <Typography variant="h6">Forum</Typography>
              <Typography>
                Check out <a href="https://thelawnforum.com/viewtopic.php?f=4&t=29448">The Lawn Forum thread</a> for common issues and feedback.
              </Typography>
              
              <Typography variant="h6">Email</Typography>
              <Typography>
                Or, send an email to <a href="mailto:help@lawntrack.app">help@lawntrack.app</a>
              </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}