import './App.css';
import React from 'react';
import Auth from './Auth';
import ErrorBoundary from './ErrorBoundary';

export default function App() {
  return (
  <ErrorBoundary>
      <Auth/>
  </ErrorBoundary>
  )
}
