import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextField, Button, Typography, Switch, FormControl, MenuItem, InputLabel, Select, InputAdornment, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { addFert } from './api/api';
import { getFert, updateFert, deleteFert } from './api/api';
import { productUnits } from './productUnits';
import { nutrients } from './nutrients';
import Title from './Title';
import NewFertilizerNutrient from './NewFertilizerNutrient';
import {  useParams} from "react-router-dom";
import { getAnalytics, logEvent  } from "firebase/analytics";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function NewFertilizer(props) {
  let history = useHistory();
  let { fertId } = useParams();
  const classes = useStyles();
  const [name, setName] = useState("");
  props.setTitle("New Fertilizer" + (name.length === 0 ? "" : (" : " + name)))
  
  const [isLiquid, setIsLiquid] = useState(false);  
  const [weightUnit, setWeightUnit] = useState("");
  const [weight, setWeight] = useState("");
  const [volumeUnit, setVolumeUnit] = useState("");
  const [volume, setVolume] = useState("");

  const [nameValid, setNameValid] = useState(true);
  const [weightUnitValid, setWeightUnitValid] = useState(true);
  const [weightValid, setWeightValid] = useState(true);
  const [volumeUnitValid, setVolumeUnitValid] = useState(true);
  const [volumeValid, setVolumeValid] = useState(true);


  const [nutrientsAdded, setNutrientsAdded] = useState([]);
  const [getAppsPending, setGetAppsPending] = React.useState(true);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const analytics = getAnalytics();

  useEffect(() => {  
    const fetchFert = async () => {
      try{
        logEvent(analytics, 'fertilizer.fetchFert.started');
        const result = await getFert(getAccessTokenSilently, fertId);
        setName(result.data.name);   
        setIsLiquid(result.data.isLiquid);
        setWeightUnit(result.data.weightUnit);
        setWeight(result.data.weight);
        setVolumeUnit(result.data.volumeUnit);
        setVolume(result.data.volume);
        setNutrientsAdded(result.data.nutrients);
        logEvent(analytics, 'fertilizer.fetchFert.success');
      }
      catch{
        logEvent(analytics, 'fertilizer.fetchFert.failed');
        alert("error, try again")
      }
      setGetAppsPending(false);
    };   
    if(fertId)
    {
      fetchFert(); 
    }  
    else{      
      setGetAppsPending(false);
    }
  }, [analytics, fertId, getAccessTokenSilently]);
  
  const handleAdd = async () => {
    setSubmitPending(true);
    try{
      if(name.length === 0)
      {
        setNameValid(false);
        setSubmitPending(false);
        alert("Please enter a name")
        return;
      }
      if(isLiquid && volumeUnit.length === 0)
      {
        setVolumeUnitValid(false);
        setSubmitPending(false);
        alert("Please select a volume unit")
        return;
      }
      if(isLiquid && volume.length === 0)
      {
        setVolumeValid(false);
        setSubmitPending(false);
        alert("Please enter a numeric volume")
        return;
      }      
      if(weightUnit.length === 0)
      {
        setWeightUnitValid(false);
        setSubmitPending(false);
        alert("Please select a weight unit")
        return;
      }
      if(weight.length === 0)
      {
        setWeightValid(false);
        setSubmitPending(false);
        alert("Please enter a numeric weight")
        return;
      }
      if(nutrientsAdded.length === 0)
      {
        setSubmitPending(false);
        alert("Please add atleast one nutrient")
        return;
      }

      if(fertId)
      {
        await updateFert(getAccessTokenSilently, {
          fertId: fertId,
          name: name,
          isLiquid: isLiquid,
          weightUnit: weightUnit,
          weight: weight,
          volumeUnit: volumeUnit,
          volume: volume,
          nutrients: nutrientsAdded
        });
      }
      else{
        await addFert(getAccessTokenSilently, {
          name: name,
          isLiquid: isLiquid,
          weightUnit: weightUnit,
          weight: weight,
          volumeUnit: volumeUnit,
          volume: volume,
          nutrients: nutrientsAdded
        });
      }
      history.push("/inventory");
    }
    catch{
      alert("failed, try again");
    }
    
    setSubmitPending(false);
    
  }

  const handleIsLiquid = (isLiquid) => {
    setIsLiquid(isLiquid);
    setVolumeUnit("");
    setVolume("");
  }

  const handleName = (val) => {
    setName(val);
    if(val.length === 0)
      setNameValid(false);
    else
    setNameValid(true);
  }

  const handleWeightUnit = (val) => {
    setWeightUnit(val);
    if(val.length === 0)
      setWeightUnitValid(false);
    else
    setWeightUnitValid(true);
  }

  const handleWeight = (val) => {
    setWeight(val);
    if(val.length === 0)
      setWeightValid(false);
    else
    setWeightValid(true);
  }

  const handleVolumeUnit = (val) => {
    setVolumeUnit(val);
    if(val.length === 0)
      setVolumeUnitValid(false);
    else
    setVolumeUnitValid(true);
  }

  const handleVolume = (val) => {
    setVolume(val);
    if(val.length === 0)
      setVolumeValid(false);
    else
    setVolumeValid(true);
  }

  const handleDeleteNutrient = (id) => {
    setNutrientsAdded(nutrientsAdded.filter(x => x.id !== id));
  }

  const handleDelete = async () => {
    setDeletePending(true);
    try{
      await deleteFert(getAccessTokenSilently, fertId);
      history.push("/inventory");
    }
    catch{
      alert("failed, try again");
      setDeletePending(false);
    }
  }


  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
              <Grid container spacing={1}>
                {getAppsPending ? <Grid item xs={12}><CircularProgress/></Grid> :
                (<>
                <Grid item xs={12}>
                 <TextField fullWidth label="Name" value={name} error={!nameValid} onChange={(e) => handleName(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Granular</Grid>
                      <Grid item>
                        <Switch checked={isLiquid} onChange={(e) => handleIsLiquid(e.target.checked)} />
                      </Grid>
                      <Grid item>Liquid</Grid>
                    </Grid>
                  </Typography>
                </Grid>                
                {isLiquid && (<>
                <Grid item xs={12}>
                  <Typography>Both volume and weight are required to calculate cumulative fertilizer usage</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="product-rate-label">Volume Unit</InputLabel>
                    <Select labelId="product-rate-label" value={volumeUnit} error={!volumeUnitValid} onChange={(e) => handleVolumeUnit(e.target.value)}>
                      {productUnits.filter(unit => unit.isLiquid).map((row) => (
                        <MenuItem value={row.value}>{row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <TextField fullWidth value={volume} error={!volumeValid} label="Volume" InputProps={{
                    endAdornment: <InputAdornment position="end">{productUnits[volumeUnit]?.label ?? ""}</InputAdornment>,
                  }} type="number" onChange={(e) => handleVolume(e.target.value)}/>
                </Grid></>)}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="product-rate-label">Weight Unit</InputLabel>
                    <Select labelId="product-rate-label" value={weightUnit} error={!weightUnitValid} onChange={(e) => handleWeightUnit(e.target.value)}>
                      {productUnits.filter(unit => !unit.isLiquid).map((row) => (
                        <MenuItem value={row.value}>{row.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <TextField fullWidth label="Weight" value={weight} error={!weightValid} InputProps={{
                    endAdornment: <InputAdornment position="end">{productUnits[weightUnit]?.label ?? ""}</InputAdornment>,
                  }} type="number" onChange={(e) => handleWeight(e.target.value)}/>
                </Grid>
                
                <Grid item xs={12}>
                <Title>Nutrients</Title>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Percentage</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nutrientsAdded.sort((a,b) => a.id - b.id).map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{nutrients[row.id].label}</TableCell>
                          <TableCell>{row.percentage}</TableCell>
                          <TableCell align="right"><Button onClick={() => handleDeleteNutrient(row.id)} variant="contained"><DeleteIcon/></Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>  
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={deletePending || submitPending} variant="contained" color="default" onClick={history.goBack}><ArrowBackIcon/></Button>
                    {fertId && (<Button disabled={deletePending || submitPending} variant="contained" color="secondary" onClick={handleDelete}><DeleteIcon/></Button>)}
                    <Button disabled={deletePending || submitPending} variant="contained" color="primary" onClick={handleAdd}><SaveIcon/></Button>
                  </Grid>
                </Grid>
                </>)}
                </Grid>
              </Paper>
            </Grid>
            <NewFertilizerNutrient submitPending={deletePending || submitPending} nutrientsAdded={nutrientsAdded} setNutrientsAdded={setNutrientsAdded}/>
          </Grid>
        </Container>
  );
}