import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextField, Button, Typography, Switch, FormControl, MenuItem, InputLabel, Select, InputAdornment, IconButton, Popper } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProduct, addProduct, updateProduct, deleteProduct } from './api/api';
import { areaUnits } from './areaUnits';
import { productUnits } from './productUnits';
import {  useParams} from "react-router-dom";
import { getAnalytics, logEvent  } from "firebase/analytics";
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function NewProduct(props) {
  let history = useHistory();
  let { productId } = useParams();
  const classes = useStyles();
  const [name, setName] = useState("");
  props.setTitle((productId ? "Update Product" : "New Product" ) + (name.length === 0 ? "" : (" : " + name)))
  
  const [isLiquid, setIsLiquid] = useState(false);  
  const [qtyUnit, setQtyUnit] = useState("");
  const [qty, setQty] = useState("");  
  const [defaultProductUnit, setDefaultProductUnit] = useState("");
  const [defaultAreaUnit, setDefaultAreaUnit] = useState("");
  const [defaultRate, setDefaultRate] = useState("");
  const [defaultInterval, setDefaultInterval] = useState("");
  const [defaultGdd, setDefaultGdd] = useState(false);  

  const [nameValid, setNameValid] = useState(true);
  const [unitValid, setUnitValid] = useState(true);
  const [qtyValid, setQtyValid] = useState(true);  

  const [getAppsPending, setGetAppsPending] = React.useState(true);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const analytics = getAnalytics();

  const handleName = (val) => {
    setName(val);
    if(val.length === 0)
      setNameValid(false);
    else
    setNameValid(true);
  }

  const handleUnit = (val) => {
    setQtyUnit(val);
    if(val.length === 0)
      setUnitValid(false);
    else
    setUnitValid(true);
  }

  const handleQty = (val) => {
    setQty(val);
    if(val.length === 0)
      setQtyValid(false);
    else
    setQtyValid(true);
  }

  useEffect(() => {  
    const fetchProduct = async () => {
      try{
        logEvent(analytics, 'product.fetchProduct.started');
        const result = await getProduct(getAccessTokenSilently, productId);
        setName(result.data.name);   
        setIsLiquid(result.data.isLiquid);
        setQtyUnit(result.data.unit);
        setQty(result.data.qty);
        setDefaultProductUnit(result.data.defaultProductUnit);
        setDefaultAreaUnit(result.data.defaultAreaUnit);
        setDefaultRate(result.data.defaultRate);
        setDefaultInterval(result.data.defaultInterval);
        setDefaultGdd(result.data.defaultGdd)

        logEvent(analytics, 'product.fetchProduct.success');
      }
      catch{
        logEvent(analytics, 'product.fetchProduct.failed');
        alert("error, try again")
      }
      setGetAppsPending(false);
    };   
    if(productId)
    {
      fetchProduct(); 
    }  
    else{      
      setGetAppsPending(false);
    }
  }, [analytics, productId, getAccessTokenSilently]);
  
  const handleAdd = async () => {
    setSubmitPending(true);
    if(name.length === 0)
    {
      setNameValid(false);
      setSubmitPending(false);
      alert("Please enter a name")
      return;
    }
    if(qtyUnit.length === 0)
    {
      setUnitValid(false);
      setSubmitPending(false);
      alert("Please select a qty unit")
      return;
    }
    if(qty.length === 0)
    {
      setQtyValid(false);
      setSubmitPending(false);
      alert("Please enter a qty")
      return;
    }

    try{
      if(productId)
      {
        await updateProduct(getAccessTokenSilently, {
          productId: productId,
          name: name,
          isLiquid: isLiquid,
          unit: qtyUnit,
          qty: qty,
          defaultProductUnit: defaultProductUnit,
          defaultAreaUnit: defaultAreaUnit,
          defaultRate: defaultRate,
          defaultGdd: defaultGdd,
          defaultInterval: defaultInterval   
        });
      }
      else{
        await addProduct(getAccessTokenSilently, {
          name: name,
          isLiquid: isLiquid,
          unit: qtyUnit,
          qty: qty,
          defaultProductUnit: defaultProductUnit,
          defaultAreaUnit: defaultAreaUnit,
          defaultRate: defaultRate,
          defaultGdd: defaultGdd,
          defaultInterval: defaultInterval
        });
      }
      history.push("/inventory");
    }
    catch{
      alert("failed, try again");
    }
    
    setSubmitPending(false);
    
  }

  const handleIsLiquid = (isLiquid) => {
    setIsLiquid(isLiquid);
    setQtyUnit("");
    setQty("");
    setDefaultProductUnit("");
    setDefaultRate("");
  }

  const handleDelete = async () => {
    setDeletePending(true);
    try{
      await deleteProduct(getAccessTokenSilently, productId);
      history.push("/inventory");
    }
    catch{
      alert("failed, try again");
      setDeletePending(false);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
              <Grid container spacing={1}>
                {getAppsPending ? <Grid item xs={12}><CircularProgress/></Grid> :
                (<>
                  <Grid item xs={12}>
                  <TextField fullWidth label="Name *" value={name} error={!nameValid} onChange={(e) => handleName(e.target.value)}/>
                  </Grid>
                  <Grid item xs={12} component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>
                        Granular
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch checked={isLiquid} onChange={(e) => handleIsLiquid(e.target.checked)} />
                    </Grid>
                    <Grid item>                        
                      <Typography>
                        Liquid
                      </Typography>
                    </Grid>
                  </Grid>                  
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="product-rate-label">Qty Unit *</InputLabel>
                      <Select labelId="product-rate-label" value={qtyUnit} error={!unitValid} onChange={(e) => handleUnit(e.target.value)}>
                        {productUnits.filter(unit => unit.isLiquid === isLiquid).map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                  <TextField fullWidth label="Qty Available *" value={qty} error={!qtyValid} InputProps={{
                      endAdornment: <InputAdornment position="end">{productUnits[qtyUnit]?.label ?? ""}</InputAdornment>,
                    }} type="number" onChange={(e) => handleQty(e.target.value)}/>
                  </Grid>                 
                  <Accordion style={{margin: "10px"}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>Default Application</Typography>
                      <Typography className={classes.secondaryHeading}>Optional</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item xs={9} md={10}>
                        <FormControl fullWidth>
                          <InputLabel id="default-rate-label">Measurement Unit</InputLabel>
                          <Select labelId="default-rate-label" value={defaultProductUnit} onChange={(e) => setDefaultProductUnit(e.target.value)}>
                            <MenuItem value="">Not Set</MenuItem>
                            {productUnits.filter(item => item.isLiquid === isLiquid).map((row) => (
                              <MenuItem value={row.value}>{row.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <IconButton onClick={handleClick} aria-label="Not what you expected? Check Granular/Liquid setting.">
                          <HelpIcon />
                        </IconButton>
                        <Popper style={{"maxWidth": "50%"}} id={id} open={open} anchorEl={anchorEl}>
                          <Paper  className={classes.paper}>Not what you expected? Check Granular/Liquid setting.</Paper>
                        </Popper>
                      </Grid>  
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="default-rate-label">Area Unit</InputLabel>
                          <Select labelId="default-rate-label" value={defaultAreaUnit} onChange={(e) => setDefaultAreaUnit(e.target.value)}>
                            <MenuItem value="">Not Set</MenuItem>
                            {areaUnits.map((row) => (
                              <MenuItem value={row.value}>{row.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="Rate" type="number" InputProps={{
                                endAdornment: <InputAdornment position="end">{(productUnits[defaultProductUnit]?.label ?? "") + "/" + (areaUnits[defaultAreaUnit]?.label ?? "")}</InputAdornment>,
                              }} value={defaultRate}  onChange={(e) => setDefaultRate(e.target.value)}/>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label={"Interval"} type="number"  InputProps={{
                                endAdornment: <InputAdornment position="end">{defaultGdd ? "GDD" : "Days"}</InputAdornment>,
                              }} value={defaultInterval} onChange={(e) => setDefaultInterval(e.target.value)}/>
                      </Grid>
                      <Grid item xs={12} component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography>
                            Days
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch checked={defaultGdd} onChange={(e) => setDefaultGdd(e.target.checked)} />
                        </Grid>
                        <Grid item>                        
                          <Typography>
                            GDD
                          </Typography>
                        </Grid>
                      </Grid>    
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                
                
                
                  <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Button disabled={deletePending || submitPending} variant="contained" color="default" onClick={history.goBack}><ArrowBackIcon/></Button>
                      {productId && (<Button disabled={deletePending || submitPending} variant="contained" color="secondary" onClick={handleDelete}><DeleteIcon/></Button>)}
                      <Button disabled={deletePending || submitPending} variant="contained" color="primary" onClick={handleAdd}><SaveIcon/></Button>
                    </Grid>
                  </Grid>
                </>)}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}