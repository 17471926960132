import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { addArea } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import { areaUnits } from './areaUnits';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, Select } from '@material-ui/core';
import { grassTypes } from './grassTypes';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function NewArea(props) {
  let history = useHistory();
  const classes = useStyles();
  const [name, setName] = useState("");
  props.setTitle("New Area" + (name.length === 0 ? "" : (" : " + name)));
  const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  const [grassType, setGrassType] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [unitValid, setUnitValid] = useState(true);
  const [sizeValid, setSizeValid] = useState(true);
  const [grassTypeValid, setGrassTypeValid] = useState(true);
  const [submitPending, setSubmitPending] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleName = (val) => {
    setName(val);
    if(val.length === 0)
      setNameValid(false);
    else
    setNameValid(true);
  }

  const handleUnit = (val) => {
    setUnit(val);
    if(val.length === 0)
      setUnitValid(false);
    else
    setUnitValid(true);
  }

  const handleSize = (val) => {
    setSize(val);
    if(val.length === 0)
      setSizeValid(false);
    else
    setSizeValid(true);
  }

  const handleGrassType = (val) => {
    setGrassType(val);
    if(val.length === 0)
      setGrassTypeValid(false);
    else
    setGrassTypeValid(true);
  }

  const handleAdd = async () => {
    setSubmitPending(true);
    if(name.length === 0)
    {
      setNameValid(false);
      setSubmitPending(false);
      alert("Please enter a name")
      return;
    }
    if(unit.length === 0)
    {
      setUnitValid(false);
      setSubmitPending(false);
      alert("Please select a unit")
      return;
    }
    if(size.length === 0)
    {
      setSizeValid(false);
      setSubmitPending(false);
      alert("Please enter a numeric size")
      return;
    }
    
    if(grassType.length === 0)
    {
      setGrassTypeValid(false);
      setSubmitPending(false);
      alert("Please select a lawn type")
      return;
    }
    try{
      await addArea(getAccessTokenSilently, {
        name: name,
        unit: unit,
        size: size,
        gddBase: grassType * 10
      })
      history.push("/areas");
    }
    catch{
      alert("error, try again");
    }
    setSubmitPending(false);
  }

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                 <TextField fullWidth label="Name" value={name} error={!nameValid} onChange={(e) => handleName(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="unit-label">Unit</InputLabel>
                      <Select labelId="unit-label" value={unit} error={!unitValid} onChange={(e) => handleUnit(e.target.value)}>
                        {areaUnits.map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                <Grid item xs={12}>
                <TextField fullWidth  label="Size" type="number" value={size} error={!sizeValid} onChange={(e) => handleSize(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                      <InputLabel id="unit-label">Lawn Type</InputLabel>
                      <Select labelId="unit-label" value={grassType} error={!grassTypeValid} onChange={(e) => handleGrassType(e.target.value)}>
                        {grassTypes.map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={submitPending} variant="contained" color="secondary" onClick={history.goBack}>Cancel</Button>
                    <Button disabled={submitPending} variant="contained" color="primary" onClick={handleAdd}>{"Add "}<SaveIcon/></Button>
                  </Grid>
                </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}