import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { TextField, Button, Typography, InputAdornment, IconButton, Popper } from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import {  getProducts } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import { productUnits } from './productUnits';
import { areaUnits } from './areaUnits';
import { getAnalytics, logEvent  } from "firebase/analytics";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function NewApplicationProduct(props) {
  const classes = useStyles();  
  const [getProductsPending, setGetProductsPending] = useState(true);
  const [products, setProducts] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const analytics = getAnalytics();

  useEffect(() => {  
    const fetchProducts = async () => {
      try{
        logEvent(analytics, 'newAppProduct.fetchProducts.started');
        const result = await getProducts(getAccessTokenSilently)
        setProducts(result.data);
        logEvent(analytics, 'newAppProduct.fetchProducts.success');
      }
      catch{
        logEvent(analytics, 'newAppProduct.fetchProducts.failed');
        alert("error, try again")
      }
      setGetProductsPending(false);
    };   
    fetchProducts(); 
  }, [analytics, getAccessTokenSilently]);

  const [productId, setProductId] = useState("");
  const [productProductUnit, setProductProductUnit] = useState("");
  const [productAreaUnit, setProductAreaUnit] = useState("");
  const [productRate, setProductRate] = useState("");
  const [productInterval, setProductInterval] = useState("");
  const [productGdd, setProductGdd] = useState(false);  
  const [productIdValid, setProductIdValid] = useState(true)
  const [productProductUnitValid, setProductProductUnitValid] = useState(true)
  const [productAreaUnitValid, setProductAreaUnitValid] = useState(true)
  const [productRateValid, setProductRateValid] = useState(true)
  const [productIntervalValid, setProductIntervalValid] = useState(true)

  const handleAddProduct = () => {
    if(productId.length === 0)
    { 
      setProductIdValid(false);
      alert("Please select a product")
      return;
    }
    if(productProductUnit.length === 0)
    { 
      setProductProductUnitValid(false);
      alert("Please select a product measurement unit")
      return;
    }
    if(productAreaUnit.length === 0)
    { 
      setProductAreaUnitValid(false);
      alert("Please select a product area unit")
      return;
    }
    if(productRate.length === 0)
    { 
      setProductRateValid(false);
      alert("Please select a product rate")
      return;
    }
    if(productInterval.length === 0)
    { 
      setProductIntervalValid(false);
      alert("Please select a product interval")
      return;
    }

    props.setProductsAdded([...props.productsAdded, {
      productId: productId,
      name: products.find(x => x.id === productId).name,
      areaUnit: productAreaUnit,
      productUnit: productProductUnit,
      rate: productRate,
      interval: productInterval,
      gdd: productGdd
    }])
    setProductId("");
    setProductRate("");
    setProductAreaUnit("");
    setProductProductUnit("");
    setProductGdd(false);
    setProductInterval("");
  }

  const handleProductId = (val) => {    
    setProductId(val);
    setProductAreaUnitValid(true);
    setProductProductUnitValid(true);
    setProductRateValid(true);
    setProductIntervalValid(true);
    if(val.length === 0)
    {
      setProductIdValid(false);
      setProductProductUnit("");
      setProductAreaUnit("");
      setProductRate("");
      setProductInterval("");
      setProductGdd("");
    }
    else
    {
      setProductIdValid(true);
      const product = products.find(x => x.id === val)
      setProductProductUnit(product?.defaultProductUnit ?? "");
      setProductAreaUnit(product?.defaultAreaUnit ?? "");
      setProductRate(product?.defaultRate ?? "");
      setProductInterval(product?.defaultInterval ?? "");
      setProductGdd(product?.defaultGdd ?? false);
    }

  }

  const handleProductAreaUnit = (val) => {    
    setProductAreaUnit(val);
    if(val.length === 0)
      setProductAreaUnitValid(false);
    else
      setProductAreaUnitValid(true);
  }

  const handleProductProductUnit = (val) => {    
    setProductProductUnit(val);
    if(val.length === 0)
      setProductProductUnitValid(false);
    else
      setProductProductUnitValid(true);
  }

  const handleProductRate = (val) => {    
    setProductRate(val);
    if(val.length === 0)
      setProductRateValid(false);
    else
      setProductRateValid(true);
  }
  const handleProductInterval = (val) => {    
    setProductInterval(val);
    if(val.length === 0)
      setProductIntervalValid(false);
    else
      setProductIntervalValid(true);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (            
          <Grid item xs={12}>
            <Paper className={classes.paper}>
            { getProductsPending ? 
                (
                  <Grid container direction="row" justify="center" alignItems="center">
                  <CircularProgress />
                  </Grid>
                ) : (<Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>Add Product</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="product-label" error={!productIdValid}>Product</InputLabel>
                      <Select labelId="product-label" error={!productIdValid} value={productId} onChange={(e) => handleProductId(e.target.value)}>
                        {products.filter(item => !props.productsAdded.map(x => x.productId).includes(item.id)).sort((a,b) => a.name.localeCompare(b.name)).map((row) => (
                          <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>                  
                  </Grid>
                  <Grid item xs={9} md={10}>
                    <FormControl fullWidth>
                      <InputLabel id="product-rate-label" error={!productProductUnitValid}>Measurement Unit</InputLabel>
                      <Select labelId="product-rate-label" error={!productProductUnitValid} value={productProductUnit} onChange={(e) => handleProductProductUnit(e.target.value)}>
                        {productId.length === 0 ?
                        (<MenuItem value="">
                          <em>No Product Selected</em>
                        </MenuItem>) 
                        : productUnits.filter(item => item.isLiquid === products.find(x => x.id === productId)?.isLiquid).map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <IconButton onClick={handleClick} aria-label="Not what you expected? Check product details in inventory.">
                      <HelpIcon />
                    </IconButton>
                    <Popper style={{"maxWidth": "50%"}} id={id} open={open} anchorEl={anchorEl}>
                      <Paper  className={classes.paper}>Not what you expected? Check product details in inventory.</Paper>
                    </Popper>
                </Grid>  
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="product-rate-label" error={!productAreaUnitValid}>Area Unit</InputLabel>
                      <Select labelId="product-rate-label" error={!productAreaUnitValid} value={productAreaUnit} onChange={(e) => handleProductAreaUnit(e.target.value)}>
                        {productId.length === 0 ?
                        (<MenuItem value="">
                          <em>No Product Selected</em>
                        </MenuItem>) 
                        : areaUnits.map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Rate" type="number" InputProps={{
                            endAdornment: <InputAdornment position="end">{(productUnits[productProductUnit]?.label ?? "") + "/" + (areaUnits[productAreaUnit]?.label ?? "")}</InputAdornment>,
                          }} error={!productRateValid} value={productRate}  onChange={(e) => handleProductRate(e.target.value)}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label={"Interval"} type="number"  InputProps={{
                            endAdornment: <InputAdornment position="end">{productGdd ? "GDD" : "Days"}</InputAdornment>,
                          }} error={!productIntervalValid} value={productInterval} onChange={(e) => handleProductInterval(e.target.value)}/>
                  </Grid>
                  <Grid item xs={12} component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>
                        Days
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch checked={productGdd} onChange={(e) => setProductGdd(e.target.checked)} />
                    </Grid>
                    <Grid item>                        
                      <Typography>
                        GDD
                      </Typography>
                    </Grid>
                  </Grid>     
                  <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Button disabled={props.submitPending} variant="contained" color="primary" onClick={handleAddProduct}>{"Add "}<AddIcon/></Button>
                    </Grid>
                  </Grid>
                </Grid>)}
              </Paper>
            </Grid>
  );
}