import React , { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { getApps } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import { getAnalytics, logEvent  } from "firebase/analytics";
import { Chip } from '@material-ui/core';

export default function ApplicationsTable() {
  let history = useHistory();  
  const analytics = getAnalytics();
  const [getAppsPending, setGetAppsPending] = useState(true);
  const [apps, setApps] = useState([]);  
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {    
    const fetchApps = async () => {

      try{    
        logEvent(analytics, 'applicationsTable.getApps.started');
        const result = await getApps(getAccessTokenSilently);
        setApps(result.data.apps);
        logEvent(analytics, 'applicationsTable.getApps.success');
      }
      catch{
        logEvent(analytics, 'applicationsTable.getApps.failed');
        alert("failed, try again")
      }
      setGetAppsPending(false);
    }; 
    fetchApps();   
  }, [analytics, getAccessTokenSilently]);

  
  
  return getAppsPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs>          
          <Title>Applications</Title>
        </Grid>
        <Grid item xs container spacing={1} justify="flex-end">
          <Grid item>
            <Chip label="Fertilizer" color="primary"/>
          </Grid>
          <Grid item>
            <Chip label="Product" color="secondary"/>
          </Grid>
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Area</TableCell>
            <TableCell>Inventory</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.sort((a, b) => moment(b.dateTime) - moment(a.dateTime)).map((row) => (
            <TableRow key={row.id} hover="true" onClick={() =>  history.push("/applications/" + row.id)}>
              <TableCell>{row.areaName}</TableCell>
              <TableCell><Grid container row spacing={1}>{row.products.map((product) => (<Grid item><Chip label={product.name} color={product.isFert ? "primary" : "secondary"}/></Grid>))}</Grid></TableCell>
              <TableCell>{moment(row.dateTime).format('MMM Do YY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}