import React , { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProducts } from './api/api';
import { getAnalytics, logEvent  } from "firebase/analytics";
import { productUnits } from './productUnits';

export default function ProductsTable() {
  let history = useHistory();
  const [getProductsPending, setGetProductsPending] = useState(true);
  const [products, setProducts] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const analytics = getAnalytics();

  useEffect(() => {  
    const fetchProducts = async () => {
      try{
        logEvent(analytics, 'productsTable.fetchProducts.started');
        const result = await getProducts(getAccessTokenSilently);
        setProducts(result.data);
        logEvent(analytics, 'productsTable.fetchProducts.success');
      }
      catch{
        logEvent(analytics, 'productsTable.fetchProducts.failed');
        alert("error, try again")
      }
      setGetProductsPending(false);
    };   
    fetchProducts();   
  }, [analytics, getAccessTokenSilently]);  
  
  return getProductsPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Title>Products</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Qty</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.sort((a,b) => a.name.localeCompare(b.name)).map((row) => (
            <TableRow key={row.id} hover="true" onClick={() =>  history.push("/products/" + row.id)}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.qty + " " + productUnits[row.unit]?.label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}