import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDashboard } from './api/api';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FertChart from './FertilizerChart';
import ProductAppsTable from './ProductAppsTable';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent  } from "firebase/analytics";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }
}));

export default function Dashboard(props) {  
  let history = useHistory();  
  const classes = useStyles();
  props.setTitle("Dashboard");
  const [getAreasPending, setGetAreasPending] = useState(true);
  const [expiringProducts, setExpiringProducts] = useState([]);
  const [cumulativeFertApps, setCumulativeFertApps] = useState([]);
  const analytics = getAnalytics();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {  
    const fetchDashboard = async () => {
      try{
        logEvent(analytics, 'dashboard.fetchDashboard.started');
        const result = await getDashboard(getAccessTokenSilently);
        setExpiringProducts(result.data.expiringProducts);
        setCumulativeFertApps(result.data.cumulativeFertApps);
        logEvent(analytics, 'dashboard.fetchAreas.success');
      }
      catch{
        logEvent(analytics, 'dashboard.fetchDashboard.failed');
        alert("error, try again")
      }
      setGetAreasPending(false);
    };   
    fetchDashboard();
  }, [analytics, history, getAccessTokenSilently]);  

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
          {getAreasPending ? 
            (
              <Grid container direction="row" justify="center" alignItems="center">
              <CircularProgress />
              </Grid>
            ) :
            (
            <>              
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <ProductAppsTable data={expiringProducts} setExpiringProducts={setExpiringProducts}/>
                </Paper>
              </Grid>
              {cumulativeFertApps.areas.map(x => 
               <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <FertChart area={x}/>
                </Paper>
              </Grid> 
              )}
            </>) 
            }
          </Grid>
        </Container>
  );
}