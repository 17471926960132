import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import {
  useParams
} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth0 } from "@auth0/auth0-react";
import { deleteArea, getArea} from './api/api';
import { grassTypes } from './grassTypes';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, Select } from '@material-ui/core';
import { areaUnits } from './areaUnits';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getAnalytics, logEvent  } from "firebase/analytics";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Area(props) {
  let history = useHistory();
  let { areaId } = useParams();
  const classes = useStyles();
  const [name, setName] = useState(""); 
  const [open, setOpen] = React.useState(false);
  const analytics = getAnalytics();

  props.setTitle("Area" + (name.length === 0 ? "" : (" : " + name)))
  
  const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  const [grassType, setGrassType] = useState("");
  const handleName = (e) => {
    setName(e.target.value);
  }
  const [getAreaPending, setGetAreaPending] = useState(true);
  const [deletePending, setDeletePending] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {    
    const fetchArea = async () => {
      try{
        logEvent(analytics, 'area.fetchArea.started');
        const result = await getArea(getAccessTokenSilently, areaId);
        setName(result.data.name);   
        setUnit(result.data.unit);
        setSize(result.data.size);
        setGrassType(result.data.gddBase === 0 ? grassTypes[0].value : grassTypes[result.gddBase / 10].value);
        logEvent(analytics, 'area.fetchArea.success');
      }
      catch{
        logEvent(analytics, 'area.fetchArea.failed');
        alert("error, try again");
      }
      setGetAreaPending(false);
    }; 
    fetchArea();   
  }, [analytics, areaId, getAccessTokenSilently]);

  
  
  const handleDelete = async () => {
    setDeletePending(true);
    try{
      await deleteArea(getAccessTokenSilently, {   
        areaId: areaId
      });
      history.push("/areas");
    }
    catch{
      alert("error, try again")
    }
    setDeletePending(false);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
              <Grid container spacing={1}>
              {getAreaPending ? <Grid item xs={12}><CircularProgress/></Grid> :
              (<>
                <Grid item xs={12}>
                 <TextField fullWidth label="Name" value={name} onChange={handleName}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="unit-label">Unit</InputLabel>
                      <Select labelId="unit-label" value={unit} onChange={(e) => setUnit(e.target.value)}>
                        {areaUnits.map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                <Grid item xs={12}>
                <TextField fullWidth  label="Size" type="number" value={size} onChange={(e) => setSize(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                      <InputLabel id="unit-label">Lawn Type</InputLabel>
                      <Select labelId="unit-label" value={grassType} onChange={(e) => setGrassType(e.target.value)}>
                        {grassTypes.map((row) => (
                          <MenuItem value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={deletePending} variant="contained" onClick={history.goBack}>Cancel</Button>
                    <Button disabled={deletePending} variant="contained" color="secondary"  onClick={handleClickOpen}>Delete<DeleteIcon/></Button>
                  </Grid>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Deleting this area will delete all assocaited applications. Weather data for your location will remain.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button disabled={deletePending} onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                      </Button>
                      
                      <Button disabled={deletePending} onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                </>)}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}