import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Line, XAxis, YAxis, ResponsiveContainer, Legend, CartesianGrid, Tooltip, LineChart } from 'recharts';
import Title from './Title';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white"
  },  
  fixedHeight: {
    height: 300,
  },
}));

export default function FertChart(props) {
  const theme = useTheme();
  const classes = useStyles();

  const formatXAxis = (date) => {
      // If using moment.js
      return moment(date * 1000).format('MMM DD');
    }
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.paper}>
          <b>{moment(label * 1000).format('MMM DD')}</b>
          {payload.map((nutrient) => (
            <p style={{color: nutrient.color}}>{nutrient.name}: {nutrient.value.toFixed(1)} lbs/k</p>)
          )}
        </div>
      );
    }
  
    return null;
  };

  return (
    <React.Fragment>
      <Title>Cumulative Fertilizer: {props.area.areaName}</Title>
      <div className={classes.fixedHeight}>
        <ResponsiveContainer >
          <LineChart
            data={props.area.data}
            margin={{
              top: 16,
              right: 0,
              bottom: 0,
              left: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ticks" tickCount={7} interval="preserveEnd" type="number" domain={[]}  stroke={theme.palette.text.secondary} tickFormatter={formatXAxis}/>
            <YAxis unit=" lbs/k" stroke={theme.palette.text.secondary}>
            </YAxis>
            <Legend verticalAlign="top" height={36} la labelFormatter={() => "hello" }/>
            <Line type="monotone"  dataKey="n" stroke={"blue"} dot={true} name={"N"} connectNulls />
            <Line type="monotone"  dataKey="p"  stroke={"red"} dot={true}  name={"P"} connectNulls/>
            <Line type="monotone"  dataKey="k" stroke={"grey"} dot={true} name={"K"} connectNulls/>
            <Line type="monotone"  dataKey="fe" stroke={"green"} dot={true} name={"Fe"} connectNulls/>
            <Line type="monotone"  dataKey="mg" stroke={"orange"} dot={true} name={"Mg"} connectNulls/>          
            <Tooltip content={<CustomTooltip />}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}