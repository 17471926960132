import React , { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { getNotifications } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { getAnalytics, logEvent  } from "firebase/analytics";

export default function NotificationsTable() {
  const analytics = getAnalytics();
  const [getAppsPending, setGetAppsPending] = useState(true);
  const [apps, setApps] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {    
    const fetchNotifications = async () => {
      try{
        logEvent(analytics, 'notificationsTable.fetchNotifications.started');
        const result = await getNotifications(getAccessTokenSilently);
        setApps(result.data);
        logEvent(analytics, 'notificationsTable.fetchNotifications.success');
      }
      catch{
        logEvent(analytics, 'notificationsTable.fetchNotifications.failed');
        alert("failed, try again")
      }
      setGetAppsPending(false);
    }; 
    fetchNotifications();   
  }, [analytics, getAccessTokenSilently]);

  
  

  return getAppsPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Title>Notifications</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Message</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{!row.read && <NewReleasesIcon/>}{row.message}</TableCell>
              <TableCell align="right">{moment(row.dateTime).format('MMM Do YY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}