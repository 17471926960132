import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Line, XAxis, YAxis, ResponsiveContainer, Legend, CartesianGrid, Tooltip, LineChart } from 'recharts';
import Title from './Title';
import moment from 'moment';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white"
  },  
  fixedHeight: {
    height: 300,
  },
}));

export default function IrrigationChart(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [duration, setDuration] = useState(7);

  const formatXAxis = (date) => {
      // If using moment.js
      return moment(date * 1000).format('MMM DD');
    }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.paper}>
          <b>{moment(label * 1000).format('MMM DD')}</b>
          {payload.map((nutrient) => (
            <p style={{color: nutrient.color}}>{nutrient.name}: {nutrient.value.toFixed(1)}</p>)
          )}
        </div>
      );
    }
  
    return null;
  };
  
  const durations = [
    {
      label: "7 days",
      value: 7
    },
    {
      label: "14 days",
      value: 14
    },
    {
      label: "28 days",
      value: 28
    },
    {
      label: "year",
      value: 365
    }
  ]

  return (
    <React.Fragment>
      <Title>Water</Title>      
      <Grid item xs={12}>
        <FormControl>
          <InputLabel id="unit-label">Last</InputLabel>
          <Select labelId="unit-label" value={duration} onChange={(e) => setDuration(e.target.value)}>
            {durations.map((row) => (
              <MenuItem value={row.value}>{row.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <div className={classes.fixedHeight}>
        <ResponsiveContainer>
          <LineChart 
            data={props.data.filter(x => moment(x.ticks * 1000) > moment().add(-duration, 'days'))}
            margin={{
              top: 16,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ticks" type="number" tickCount={7} interval="preserveEnd" domain={[]} stroke={theme.palette.text.secondary} tickFormatter={formatXAxis}/>
            <YAxis yAxisId="left" unit=" mm" stroke={theme.palette.text.secondary}>
            </YAxis>
            <Legend verticalAlign="top"   la labelFormatter={() => "hello" }/>
            <Line yAxisId="left" type="monotone"  dataKey="precipitationMm" stroke={"green"} dot={false} name={"Precip (mm)"} connectNulls />
            <Line yAxisId="left" type="monotone"  dataKey="coolEvapotranspirationMm"  stroke={"blue"} dot={false}  name={"Cool Evap (mm)"} connectNulls/>
            <Line yAxisId="left" type="monotone"  dataKey="warmEvapotranspirationMm"  stroke={"red"} dot={false}  name={"Warm Evap (mm)"} connectNulls/>
            <Tooltip content={<CustomTooltip />}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}