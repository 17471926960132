import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import {  updateWeather } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, Select } from '@material-ui/core';
import { tempUnits } from './tempUnits';


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function UpdateWeather(props) {
  let history = useHistory();
  const classes = useStyles();
  props.setTitle("Update Weather");
  const [date, setDate] = useState("");
  const [averageTemp, setAverageTemp] = useState("");
  const [minTemp, setMinTemp] = useState("");
  const [maxTemp, setMaxTemp] = useState("");
  const [precipitation, setPrecipitation] = useState("");
  const [unit, setUnit] = useState("");
  const [submitPending, setSubmitPending] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [dateValid, setDateValid] = useState(true)
  const [unitValid, setUnitValid] = useState(true)
  const [tempValid, setTempValid] = useState(true)
  const [minTempValid, setMinTempValid] = useState(true)
  const [maxTempValid, setMaxTempValid] = useState(true)
  const [precipitationValid, setPrecipitationValid] = useState(true)

  const handleAdd = async () => {
    setSubmitPending(true);
    if(date.length === 0)
    {
      setDateValid(false);
      setSubmitPending(false);
      alert("Please select a date")
      return;
    }
    if(unit.length === 0)
    {
      setUnitValid(false);
      setSubmitPending(false);
      alert("Please select a unit")
      return;
    }
    if(averageTemp.length === 0)
    {
      setTempValid(false);
      setSubmitPending(false);
      alert("Please input the average temp")
      return;
    }
    
    if(minTemp.length === 0)
    {
      setMinTempValid(false);
      setSubmitPending(false);
      alert("Please input the min temp")
      return;
    }
    
    if(maxTemp.length === 0)
    {
      setMaxTempValid(false);
      setSubmitPending(false);
      alert("Please input the max temp")
      return;
    }
    
    if(precipitation.length === 0)
    {
      setPrecipitationValid(false);
      setSubmitPending(false);
      alert("Please input the precipitation")
      return;
    }
  else
    try{
      await updateWeather(getAccessTokenSilently, {
        date: date,
        unit: unit,
        averageTemp: averageTemp,
        minTemp: minTemp,
        maxTemp: maxTemp,
        precipitationMm: precipitation
      })
      history.push("/weather");
    }
    catch{
      alert("failed, try again");
    }
    setSubmitPending(false);
  }

  const handleDate = (val) => {    
    setDate(val);
    if(val.length === 0)
      setDateValid(false);
    else
    setDateValid(true);
  }

  const handleUnit = (val) => {    
    setUnit(val);
    if(val.length === 0)
      setUnitValid(false);
    else
    setUnitValid(true);
  }

  const handleAverageTemp = (val) => {    
    setAverageTemp(val);
    if(val.length === 0)
      setTempValid(false);
    else
    setTempValid(true);
  }

  const handleMinTemp = (val) => {    
    setMinTemp(val);
    if(val.length === 0)
      setMinTempValid(false);
    else
    setMinTempValid(true);
  }

  const handleMaxTemp = (val) => {    
    setMaxTemp(val);
    if(val.length === 0)
      setMaxTempValid(false);
    else
    setMaxTempValid(true);
  }

  const handlePrecipitation = (val) => {    
    setPrecipitation(val);
    if(val.length === 0)
      setPrecipitationValid(false);
    else
    setPrecipitationValid(true);
  }

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField fullWidth label="Date" type="date" InputLabelProps={{
                    shrink: true,
                  }} error={!dateValid} value={date} onChange={(e) => handleDate(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="unit-label" error={!unitValid}>Unit</InputLabel>
                        <Select labelId="unit-label" error={!unitValid}  value={unit} onChange={(e) => handleUnit(e.target.value)}>
                          {tempUnits.map((row) => (
                            <MenuItem value={row.value}>{row.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Average Temp" type="number" error={!tempValid} value={averageTemp} onChange={(e) => handleAverageTemp(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Min Temp" type="number" error={!minTempValid} value={minTemp} onChange={(e) => handleMinTemp(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Max Temp" type="number" error={!maxTempValid} value={maxTemp} onChange={(e) => handleMaxTemp(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Precipitation (mm)" type="number" error={!precipitationValid} value={precipitation} onChange={(e) => handlePrecipitation(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={submitPending} variant="contained" color="secondary" onClick={history.goBack}>Cancel</Button>
                    <Button disabled={submitPending || !dateValid || !unitValid || !tempValid || !minTempValid || !maxTempValid || !precipitationValid} variant="contained" color="primary" onClick={handleAdd}>{"Add "}<SaveIcon/></Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
  );
}