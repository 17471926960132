import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsTable from './NotificationsTable';
import NotificationConfiguration from './NotificationConfiguration';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Notifications(props) {
  const classes = useStyles();
  props.setTitle("Notifications");
  props.setNotificationCount(0);
  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <NotificationConfiguration/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <NotificationsTable/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}