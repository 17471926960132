import './App.css';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems } from './listItems';
import Dashboard from './Dashboard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Applications from './Applications';
import Application from './Application';
import NewApplication from './NewApplication';
import Inventory from './Inventory';
import NewFertilizer from './NewFertilizer';
import NewProduct from './NewProduct';
import Areas from './Areas';
import NewArea from './NewArea';
import { useAuth0 } from "@auth0/auth0-react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import Weather from './Weather';
import UpdateWeather from './UpdateWeather';
import Area from './Area';
import Notifications from './Notifications';
import { getNotificationCount } from './api/api';
import UpdateLocation from './UpdateLocation';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Donate from './Donate';
import { getAnalytics, logEvent  } from "firebase/analytics";
import Help from './Help';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  copyright:{
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


export default function LoggedInApp() {
  const classes = useStyles();
  const { logout, getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(window.innerWidth > 1000);
  const [title, setTitle] = React.useState("Dashboard");
  const [notificationCount, setNotificationCount] = useState(0);
  const analytics = getAnalytics();

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try{
        logEvent(analytics, 'loggedInApp.fetchNotificationCount.started');
        const result = await getNotificationCount(getAccessTokenSilently);
        setNotificationCount(result.data);
        logEvent(analytics, 'loggedInApp.fetchNotificationCount.success');
      }
      catch{
        logEvent(analytics, 'loggedInApp.fetchNotificationCount.failed');
        alert("failed, try again")
      }
    }; 
    fetchNotificationCount();
  }, [getAccessTokenSilently, analytics])
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  return (
    <div className={classes.root}>
       <Router>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
          <IconButton to="/notifications" color="inherit" component={Link}>
            <Badge badgeContent={notificationCount} color="secondary" >
              <NotificationsIcon/>
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>        
        <Divider />
        <List>      
          <ListItem button  component={Link} to="/donate">
            <ListItemIcon>
              <CardGiftcardIcon  />
            </ListItemIcon>
            <ListItemText primary="Donate" />
          </ListItem>    
          <ListItem button  component={Link} to="/help">
            <ListItemIcon>
              <HelpIcon  />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>  
          <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route path="/inventory/newFertilizer">
            <NewFertilizer setTitle={setTitle}/>
          </Route>
          <Route path="/inventory/newProduct">
            <NewProduct setTitle={setTitle} />
          </Route>
          <Route path="/inventory">
            <Inventory setTitle={setTitle}/>
          </Route>
          <Route path="/fertilizers/:fertId">
            <NewFertilizer setTitle={setTitle}/>
          </Route>
          <Route path="/products/:productId">
            <NewProduct setTitle={setTitle}/>
          </Route>
          
          <Route path="/applications/new">
            <NewApplication setTitle={setTitle}/>
          </Route>
          <Route path="/applications/:applicationId">
            <Application setTitle={setTitle}/>
          </Route>
          <Route path="/applications">
            <Applications setTitle={setTitle}/>
          </Route>
          <Route path="/areas/new">
            <NewArea setTitle={setTitle}/>
          </Route>          
          <Route path="/areas/:areaId">
            <Area setTitle={setTitle}/>
          </Route>   
          <Route path="/areas">
            <Areas setTitle={setTitle}/>
          </Route>                  
          <Route path="/weather/update">
            <UpdateWeather setTitle={setTitle}/>
          </Route>    
          <Route path="/weather">
            <Weather setTitle={setTitle}/>
          </Route> 
          <Route path="/notifications">
            <Notifications setTitle={setTitle} setNotificationCount={setNotificationCount}/>
          </Route>    
          <Route path="/location">
            <UpdateLocation setTitle={setTitle}/>
          </Route>  
          <Route path="/donate">
            <Donate setTitle={setTitle}/>
          </Route>   
          <Route path="/help">
            <Help setTitle={setTitle}/>
          </Route>     
          <Route path="/">
            <Dashboard setTitle={setTitle}/>
          </Route>
        </Switch>
        <Typography className={classes.copyright} variant="body2" color="textSecondary" align="center">
          {'Copyright © Liam Jones '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </main>
      </Router>
    </div>
  );
}
