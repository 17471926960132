import {deleteApp, getApp} from './api/api'
import React , { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useParams
} from "react-router-dom";
import Title from './Title';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getAnalytics, logEvent  } from "firebase/analytics";
import { productUnits } from './productUnits';
import { areaUnits } from './areaUnits';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Application(props) {
  let history = useHistory();
  const analytics = getAnalytics();
  let { applicationId } = useParams();
  const classes = useStyles();
  props.setTitle("Application");
  const [getAppsPending, setGetAppsPending] = useState(true);
  const [apps, setApps] = useState([]);
  const [deletePending, setDeletePending] = useState(false);  
  const { getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {  
    const fetchApps = async () => {
      try{
        logEvent(analytics, 'application.getApps.started');
        const result = await getApp(getAccessTokenSilently, applicationId);
        setApps(result.data);   
        logEvent(analytics, 'application.getApps.success');
      }
      catch{
        logEvent(analytics, 'application.getApps.failed');
        alert("failed, try again")
      }
      setGetAppsPending(false);
    };   
    fetchApps();   
  }, [analytics, applicationId, getAccessTokenSilently]);
  


  

  const handleDelete = async () => {
    logEvent(analytics, 'application.delete.started');
    setDeletePending(true);
    try{
      await deleteApp(getAccessTokenSilently, applicationId);
      logEvent(analytics, 'application.delete.success');
      history.push("/applications");
    }
    catch{
      logEvent(analytics, 'application.delete.failed', applicationId);
      alert("failed, try again");
      setDeletePending(false);
    }
  }

  return (    
        <Container maxWidth="lg" className={classes.container}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
            
                {getAppsPending ?  
                (
                  <Grid item container direction="row" justify="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
                ) : 
                (
                  <>
                    <Grid item xs={12}>
                      <h1>{apps.areaName} on {apps.dateTime} applied {apps.isPm ? "PM" : "AM"}</h1>
                    </Grid>
                    <Grid item xs={12}>
                      <Title>Products</Title>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Interval</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {apps.fertApps.map((row) => (
                            <TableRow key={row.fertId}>
                            <TableCell>{row.fert.name}</TableCell>
                              <TableCell>{row.rate  + " " + productUnits[row.productUnit]?.label + "/" + areaUnits[row.areaUnit]?.label}</TableCell>
                              <TableCell>{""}</TableCell>
                            </TableRow>
                          ))}
                          {apps.productApps.map((row) => (
                            <TableRow key={row.productId}>
                              <TableCell>{row.product.name}</TableCell>
                              <TableCell>{row.rate  + " " + productUnits[row.productUnit]?.label + "/" + areaUnits[row.areaUnit]?.label}</TableCell>
                              <TableCell>{row.interval + " " + (row.gdd ? "gdd" : "days")}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>  
                    </Grid>  
                    <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={deletePending} variant="contained" color="secondary" onClick={handleDelete}>{"Delete "}<DeleteIcon/></Button>
                    <Button disabled={deletePending} variant="contained" color="primary" onClick={history.goBack}>Back</Button>
                  </Grid>
                </Grid>
                </>           
                )}
                </Grid>
              </Paper>
        </Container>
  );
}