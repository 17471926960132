export const areaUnits = [
    {
        value: 0,
        label: "sqft"
    },
    {
        value: 1,
        label: "sqm"
    },
    {
        value: 2,
        label: "ksqft"
    },
    {
        value: 3,
        label: "100 sqm"
    },
    {
        value: 4,
        label: "acre"
    }
]