import React from "react";
import { getAnalytics, logEvent  } from "firebase/analytics";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
      
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      logEvent(getAnalytics(), "critical.error", 
      {"name": error?.name.substr(0, 99), 
      "message": error?.message?.substr(0, 99), 
      "reactMessage": errorInfo?.componentStack?.substr(0,99)})
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div>
                <h1>Something went wrong.</h1>
                <p>An error report has been sent back, if the problem persits, email <a href="mailto:me@liamjones.me?subject=LawnTrack%20error">me@liamjones.me</a></p>
            </div>)
        ;
      }
  
      return this.props.children; 
    }
  }