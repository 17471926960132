import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {  Link} from "react-router-dom";
import { CircularProgress, Typography } from '@material-ui/core';
import WeatherTable from './WeatherTable';
import { MenuItem, Select } from '@material-ui/core';
import { tempUnits } from './tempUnits';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import WeatherChart from './WeatherChart';
import IrrigationChart from './IrrigationChart';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import { getWeather } from './api/api';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));



export default function Weather(props) {
  const classes = useStyles();
  const [unit, setUnit] = useState(1);
  props.setTitle("Weather");
  
  const [getAppsPending, setGetAppsPending] = useState(true);
  const [weather, setWeather] = useState([]);
  const analytics = getAnalytics();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {  
    const fetchWeather = async () => {
      try{
        logEvent(analytics, 'weather.fetchWeather.started');
        const result = await getWeather(getAccessTokenSilently);
        setWeather(result.data);
        logEvent(analytics, 'weather.fetchWeather.success');
      }
      catch{
        logEvent(analytics, 'weather.fetchWeather.failed');
        alert("failed, try again")
      }
      setGetAppsPending(false);
    };  
    fetchWeather();   
  }, [analytics, getAccessTokenSilently]);

  const convertTemp = (temp, oldUnit, newUnit) => {
    if(oldUnit === newUnit)
    {
      return temp;
    }
    else if(newUnit === 0)
    {
      return ((temp - 32) * 5 / 9)    
    }
    return ((temp * 9 / 5) + 32)
  }

const handleUnit = (unit) => {
  setWeather(weather.map(x => {
    x.averageTemp = convertTemp(x.averageTemp, x.unit, unit);
    x.minTemp = convertTemp(x.minTemp, x.unit, unit);
    x.maxTemp = convertTemp(x.maxTemp, x.unit, unit);
    x.unit = unit;
    return x 
  }))
  setUnit(unit);
}

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
          {getAppsPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
            <>
            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="unit-label">Unit</InputLabel>
                        <Select labelId="unit-label" value={unit} onChange={(e) => handleUnit(e.target.value)}>
                          {tempUnits.map((row) => (
                            <MenuItem value={row.value}>{row.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <Link to="/location">
                  <AddCircleOutlineIcon/> 
                  <Typography>{"Set Location - Required for weather and GDD"}</Typography>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <WeatherChart unit={unit} data={weather}/>
              </Paper>
            </Grid>
              
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <IrrigationChart unit={unit} data={weather}/>
              </Paper>
            </Grid>

            
            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <Link to="/weather/update">
                  <AddCircleOutlineIcon/> 
                  <Typography>{"Update"}</Typography>
                </Link>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <WeatherTable unit={unit} weather={weather}/>
              </Paper>
            </Grid>
              </>)}                
          </Grid>
        </Container>
  );
}