import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField, Button, Typography, Switch, IconButton, Popper, Input, Chip } from '@material-ui/core';
import {  useHistory } from "react-router-dom";
import { MenuItem, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { addApp, getAreas } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import NewApplicationFert from './NewApplicationFert';
import NewApplicationProduct from './NewApplicationProduct';
import { getAnalytics, logEvent  } from "firebase/analytics";
import moment from 'moment';
import { productUnits } from './productUnits';
import { areaUnits } from './areaUnits';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

export default function NewApplication(props) {
  let history = useHistory();
  const classes = useStyles();  
  props.setTitle("New Application");
  const [getAreasPending, setGetAreasPending] = useState(true);
  const [areas, setAreas] = useState([]);
  const analytics = getAnalytics();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => { 
    const fetchAreas = async () => {
      try{
        logEvent(analytics, 'newApplication.fetchAreas.started');
        const result = await getAreas(getAccessTokenSilently);
        setAreas(result.data);
        logEvent(analytics, 'newApplication.fetchAreas.success');
      }
      catch{
        logEvent(analytics, 'newApplication.fetchAreas.failed');
        alert("error, try again")
      }
      setGetAreasPending(false);
    };    
    fetchAreas();
  }, [getAccessTokenSilently, analytics]);
  
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [isPm, setIsPm] = useState(new Date().getHours() >= 12)
  const [areaId, setAreaId] = useState([]);
  const [fertsAdded, setFertsAdded] = useState([]);
  const [productsAdded, setProductsAdded] = useState([]);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [areaIdValid, setAreaIdValid] = useState(true)
  const [dateValid, setDateValid] = useState(true)
  
  const handleAdd = async () => {
    setSubmitPending(true); 
    if(areaId.length === 0)
    {
      setAreaIdValid(false);
      setSubmitPending(false);
      alert("Please select an area")
      return;
    }
    if(date.length === 0)
    {
      setDateValid(false);
      setSubmitPending(false);
      alert("Please select a date")
      return;
    }   
    if(productsAdded.length === 0 && fertsAdded.length === 0)
    {
      setSubmitPending(false);
      alert("Please add a fertilizer or product")
      return;
    }
    try{
        await addApp(getAccessTokenSilently, {
          dateTime: date,
          isPm: isPm,
          areaId: areaId,
          fertApps: fertsAdded,
          productApps: productsAdded
      });
      history.push("/dashboard");
    }
    catch{
      alert("failed, try again");
    }
    setSubmitPending(false);
  }

  const handleDeleteFert = (id) => {
    setFertsAdded(fertsAdded.filter(x => x.fertId !== id));
  }
  const handleDeleteProduct = (id) => {
    setProductsAdded(productsAdded.filter(x => x.productId !== id));
  }

  const handleAreaId = (val) => {  
    setAreaId(val);
    if(val.length === 0)
      setAreaIdValid(false);
    else
      setAreaIdValid(true);
  }

  const handleDate = (val) => {    
    setDate(val);
    if(val.length === 0)
      setDateValid(false);
    else
    setDateValid(true);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
             
                { getAreasPending ? 
                (
                  <Grid container direction="row" justify="center" alignItems="center">
                  <CircularProgress />
                  </Grid>
                ) :
                (
                  <Grid container spacing={1}>
                  <Grid item xs={12}>
                 <TextField fullWidth label="Date" type="date" error={!dateValid} value={date} 
                  InputLabelProps={{
                    shrink: true,
                  }} onChange={(e) => handleDate(e.target.value)}/>
                </Grid>
                <Grid item xs={9} component="label" container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography>
                      AM
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch checked={isPm} onChange={(e) => setIsPm(e.target.checked)} />
                  </Grid>
                  <Grid item>                        
                    <Typography>
                      PM
                    </Typography>
                  </Grid>
                </Grid>     
                <Grid item xs={3} >
                    <IconButton onClick={handleClick} aria-label="For product apps, AM will count interval from the date specified, PM will count from the day after.">
                      <HelpIcon />
                    </IconButton>
                    <Popper style={{"maxWidth": "50%"}} id={id} open={open} anchorEl={anchorEl}>
                      <Paper  className={classes.paper}>For product apps, AM will count interval from the date specified, PM will count from the day after.</Paper>
                    </Popper>
                </Grid>    
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">Area(s)</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      fullWidth
                      error={!areaIdValid}
                      value={areaId}
                      onChange={(e) => handleAreaId(e.target.value)}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip key={value} label={areas.find(x => x.id === value).name} className={classes.chip} />
                          ))}
                        </div>
                      )}
                    >
                      {areas.map((row) => (
                        <MenuItem value={row.id}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Title>Products</Title>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Interval</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fertsAdded.map((row) => (
                        <TableRow key={row.fertId}>
                        <TableCell>{row.name}</TableCell>
                          <TableCell>{row.rate  + " " + productUnits[row.productUnit]?.label + "/" + areaUnits[row.areaUnit]?.label}</TableCell>
                          <TableCell>{""}</TableCell>
                          <TableCell align="right"><Button onClick={() => handleDeleteFert(row.fertId)}variant="contained"><DeleteIcon/></Button></TableCell>
                        </TableRow>
                      ))}
                      {productsAdded.map((row) => (
                        <TableRow key={row.productId}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.rate  + " " + productUnits[row.productUnit]?.label + "/" + areaUnits[row.areaUnit]?.label}</TableCell>
                          <TableCell>{row.interval + " " + (row.gdd ? "GDD" : "Days")}</TableCell>
                          <TableCell align="right"><Button onClick={() => handleDeleteProduct(row.productId)} variant="contained"><DeleteIcon/></Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>  
                </Grid>              
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={submitPending} variant="contained" color="secondary" onClick={history.goBack}>Cancel</Button>
                    <Button disabled={submitPending || !areaIdValid || !dateValid } variant="contained" color="primary" onClick={handleAdd}>{"Save "}<SaveIcon/></Button>
                  </Grid>
                </Grid>
                </Grid>
                )
                }
                
              </Paper>
            </Grid>
            
            <Grid item container xs={12} md={6} spacing={3}>
              <NewApplicationFert submitPending={submitPending} fertsAdded={fertsAdded} setFertsAdded={setFertsAdded}/>
              <NewApplicationProduct submitPending={submitPending} productsAdded={productsAdded} setProductsAdded={setProductsAdded}/>
            </Grid>  
            </Grid>
        </Container>
  );
}