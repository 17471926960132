import React, {useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@material-ui/core/CircularProgress';
import LoggedInApp from './LoggedInApp';
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import Login from './Login';

export default function Auth() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
  const [attemptedRefresh, setAttemptedRefresh] = useState(false);
  const analytics = getAnalytics();

  if(isLoading)
  {
    logEvent(analytics, 'auth.loading');
    return <CircularProgress/>
  }
  if(isAuthenticated)
  {
    console.log("auth.yes");
    logEvent(analytics, 'auth.yes');
    if(user?.sub)
    {
      setUserId(analytics, user.sub)
    }
    else{
        logEvent(analytics, "error.noUserId");
    }
    return (
      <LoggedInApp/>
    );
  }
  if(!attemptedRefresh)
  {
    console.log("auth.attemptingRefresh");
    logEvent(analytics, 'auth.attemptingRefresh');
    getAccessTokenSilently().finally(() => {setAttemptedRefresh(true)});
    return <CircularProgress/>
  }

  console.log("auth.no");
  logEvent(analytics, 'auth.no');
  return <Login/>
  
  
  
}
