import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBIn5AmGiO1Pu4A4G-FJyNvuyzv90ZTAyw",
    authDomain: "lawntrackapp.firebaseapp.com",
    projectId: "lawntrackapp",
    storageBucket: "lawntrackapp.appspot.com",
    messagingSenderId: "629612572887",
    appId: "1:629612572887:web:61d2f4f02d45c803868092",
    measurementId: "G-Q4EQ18Q92D"
  };
  
initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
    domain="lawntrack.us.auth0.com"
    clientId={process.env.REACT_APP_AUTH_KEY}
    redirectUri={window.location.origin}
    audience="https://lawntrack.api"
    useRefreshTokens={true}
    cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
