import React , { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { useAuth0 } from "@auth0/auth0-react";
import { FormControlLabel, TextField, Switch, Button, InputAdornment } from '@material-ui/core';
import { getNotificationSettings, updateNotificationSettings } from './api/api';
import { getAnalytics, logEvent  } from "firebase/analytics";

export default function NotificationConfiguration(props) {
  const analytics = getAnalytics();
  const [getAppsPending, setGetAppsPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);
  const [threshold, setThreshold] = useState(0);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {   
    const fetchConfiguration = async () => {
      try{
        logEvent(analytics, 'notificationsConfiguration.fetchConfiguration.started');
        const result = await getNotificationSettings(getAccessTokenSilently);
        setThreshold(result.data.threshold);
        setEmailEnabled(result.data.emailEnabled);
        setEmailAddress(result.data.emailAddress);
        logEvent(analytics, 'notificationsConfiguration.fetchConfiguration.success');
      }
      catch{
        logEvent(analytics, 'notificationsConfiguration.fetchConfiguration.failed');
        alert("failed, try again")
      }
      setGetAppsPending(false);
    };  
    fetchConfiguration();   
  }, [analytics, getAccessTokenSilently]);

  

  const handleUpdate = async () => {
    setSubmitPending(true);
    try{
      logEvent(analytics, 'notificationsConfiguration.handleUpdate.started');
       await updateNotificationSettings(getAccessTokenSilently, {
        threshold: threshold,
        emailEnabled: emailEnabled,
        emailAddress: emailAddress
      });
      logEvent(analytics, 'notificationsConfiguration.handleUpdate.success');
    }
    catch{
      logEvent(analytics, 'notificationsConfiguration.handleUpdate.failed');
      alert("failed, try again")
    }
    setSubmitPending(false);
  }
  

  return getAppsPending || submitPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Title>Your Notification Settings</Title>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={3}>
          <TextField type="number" label="Notify me before expiry" value={threshold} onChange={(e) => setThreshold(e.target.value)} InputProps={{
            endAdornment: <InputAdornment position="end">Days</InputAdornment>,
          }}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel control={<Switch checked={emailEnabled} onChange={(e) => setEmailEnabled(e.target.checked)}/>} label="Send Emails" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField  label="Email Address" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button variant="contained" color="primary" onClick={() => handleUpdate(true)}>Update</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}