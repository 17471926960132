import axios from "axios";

const url = `${process.env.REACT_APP_FUNCTION_URI}/api`;
const getConfig = async (getAccessTokenSilently) =>{
    const accessToken = await getAccessTokenSilently();
    return {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    }
}

export async function getApp(getAccessTokenSilently, applicationId){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetApp?appId=${applicationId}`, config);
}

export async function getApps(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetApps`, config);
}

export async function deleteApp(getAccessTokenSilently, applicationId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios
        .post(`${process.env.REACT_APP_FUNCTION_URI}/api/DeleteApp`, {
          userId: "0630ad64-24e1-4327-a68e-8cc41752fea2",    
          appId: applicationId
        }, config)
}

export async function getAreas(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetAreas`, config);
}

export async function getArea(getAccessTokenSilently, areaId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetArea?areaId=${areaId}`, config);
}

export async function getFert(getAccessTokenSilently, fertId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetFert?fertId=${fertId}`, config);
}

export async function deleteFert(getAccessTokenSilently, fertId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios
    .post(`${process.env.REACT_APP_FUNCTION_URI}/api/DeleteFert`, {
      fertId: fertId
    }, config)
}

export async function updateFert(getAccessTokenSilently, fert){
    const config = await getConfig(getAccessTokenSilently);
    return await axios
    .post(`${process.env.REACT_APP_FUNCTION_URI}/api/UpdateFert`, fert, config)
}

export async function getFerts(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios
    .get(`${process.env.REACT_APP_FUNCTION_URI}/api/GetFerts`, config)
}

export async function getProduct(getAccessTokenSilently, productId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetProduct?productId=${productId}`, config);
}

export async function getProducts(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetProducts`, config);
}

export async function addApp(getAccessTokenSilently, app){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/AddApp`, app, config);
}

export async function addArea(getAccessTokenSilently, area){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/AddArea`, area, config);
}

export async function addFert(getAccessTokenSilently, fert){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/AddFert`, fert, config);
}

export async function addProduct(getAccessTokenSilently, product){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/AddProduct`, product, config);
}

export async function deleteProduct(getAccessTokenSilently, productId){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/DeleteProduct`, {
        productId: productId
      }, config);
}

export async function deleteArea(getAccessTokenSilently, area){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/DeleteArea`, area, config);
}

export async function updateProduct(getAccessTokenSilently, product){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/UpdateProduct`, product, config);
}

export async function getWeather(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetWeather`, config);
}

export async function updateWeather(getAccessTokenSilently, weather){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/UpdateWeather`, weather, config);
}

export async function getNotifications(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetNotifications`, config);
}

export async function getNotificationCount(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetNotificationCount`, config);
}

export async function getLocation(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetLocation`, config);
}

export async function updateLocation(getAccessTokenSilently, location){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/UpdateLocation`, location, config);
}

export async function getNotificationSettings(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetNotificationSettings`, config);
}

export async function updateNotificationSettings(getAccessTokenSilently, notificationSettings){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/UpdateNotificationSettings`, notificationSettings, config);
}

export async function clearExpiry(getAccessTokenSilently, expiry){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/ClearExpiry`, expiry, config);
}

export async function getDashboard(getAccessTokenSilently){
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/GetDashboard`, config);
}