import React , { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { getAreas } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import {areaUnits} from './areaUnits';
import { grassTypes } from './grassTypes';
import { getAnalytics, logEvent  } from "firebase/analytics";


export default function AreasTable() {  
  let history = useHistory();
  const analytics = getAnalytics();
  const { getAccessTokenSilently } = useAuth0();
  const [getAreasPending, setGetAreasPending] = useState(true);
  const [areas, setAreas] = useState([]);
  useEffect(() => { 
    const fetchAreas = async () => {
      try{        
        logEvent(analytics, 'areasTable.fetchAreas.started');
        const result = await getAreas(getAccessTokenSilently)
        setAreas(result.data);
        logEvent(analytics, 'areasTable.fetchAreas.success');
      }
      catch{
        logEvent(analytics, 'areasTable.fetchAreas.failed');
        alert("error, try again")
      }
      setGetAreasPending(false);
      
    };    
    fetchAreas();   
  }, [analytics, getAccessTokenSilently]);

  
  
  return getAreasPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Title>Areas</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell align="right">Lawn Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas.map((row) => (
            <TableRow key={row.id} hover="true" onClick={() =>  history.push("/areas/" + row.id)}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.size + " " + areaUnits[row.unit].label}</TableCell>
              <TableCell align="right">{row.gddBase === 0 ? grassTypes[0].label : grassTypes[row.gddBase / 10].label}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}