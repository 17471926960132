import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { TextField, Button, FormControl, MenuItem, InputLabel, Select, InputAdornment } from '@material-ui/core';
import { nutrients } from './nutrients';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function NewFertilizerNutrient(props) {
  const classes = useStyles();
  
  const [id, setId] = useState("");
  const [percentage, setPercentage] = useState("");
  const [idValid, setIdValid] = useState(true);
  const [percentageValid, setPercentageValid] = useState(true);
  
   const handleAdd = () => {
    if(id.length === 0)
    {
      setIdValid(false);
      alert("Please select a nutrient")
      return;
    }
    if(percentage.length === 0)
    {
      setPercentageValid(false);
      alert("Please enter a percentage")
      return;
    }
    props.setNutrientsAdded([...props.nutrientsAdded, {
      id: id,
      percentage: percentage
    }])
    setId("");
    setPercentage("");
   }

   const handleId = (val) => {
    setId(val);
    if(val.length === 0)
      setIdValid(false);
    else
    setIdValid(true);
  }
  const handlePercentage = (val) => {
    setPercentage(val);
    if(val.length === 0)
      setPercentageValid(false);
    else
    setPercentageValid(true);
  }
  return (           
    <Grid item xs={12} md={6}>
      <Paper className={classes.paper}>
        <Title>Add Nutrient</Title>
        <Grid container spacing={1}>                
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="nutrient-label">Nutrient</InputLabel>
              <Select labelId="nutrient-label" value={id} error={!idValid} onChange={(e) => handleId(e.target.value)}>
                {nutrients.filter(item => !props.nutrientsAdded.map(x => x.id).includes(item.value)).map((row) => (
                  <MenuItem value={row.value}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item  xs={12}>
            <TextField label="Percentage" fullWidth InputProps={{
              endAdornment: <InputAdornment position="end">{"%"}</InputAdornment>,
            }} type="number" value={percentage} error={!percentageValid} onChange={(e) => handlePercentage(e.target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <Button disabled={props.submitPending} variant="contained" color="primary" onClick={handleAdd}><AddIcon/></Button>
          </Grid>        
        </Grid>
      </Paper>
    </Grid>
  );
}