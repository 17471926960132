import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {  Link} from "react-router-dom";
import FertsTable from './FertsTable';
import ProductsTable from './ProductsTable';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Inventory(props) {
  const classes = useStyles();
  props.setTitle("Inventory");
  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <Link to="/inventory/newFertilizer">
                  <AddCircleOutlineIcon/> 
                  <Typography>{"Fertilizer"}</Typography>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper className={classes.paper}>
                <Link to="/inventory/newProduct">
                  <AddCircleOutlineIcon/> 
                  <Typography>{"Product"}</Typography>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <FertsTable/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
                <ProductsTable/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}