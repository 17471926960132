import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { TextField, Button, Typography, InputAdornment, Popper, IconButton } from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getFerts } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import { getAnalytics, logEvent  } from "firebase/analytics";
import { productUnits } from './productUnits';
import { areaUnits } from './areaUnits';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function NewApplicationFert(props) {
  const classes = useStyles();  
  const [getFertsPending, setGetFertsPending] = useState(true);
  const [ferts, setFerts] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const analytics = getAnalytics();

  useEffect(() => { 
    const fetchFerts = async () => {
      try{
        logEvent(analytics, 'newAppFert.fetchFerts.started');
        const result = await getFerts(getAccessTokenSilently)
        setFerts(result.data);
        logEvent(analytics, 'newAppFert.fetchFerts.success');
      }
      catch{
        logEvent(analytics, 'newAppFert.fetchFerts.failed');
        alert("error, try again")
      }
      setGetFertsPending(false);
    };       
    fetchFerts();  
  }, [getAccessTokenSilently, analytics]);

  const [fertId, setFertId] = useState("");
  const [fertproductUnit, setFertproductUnit] = useState("");
  const [fertAreaUnit, setFertAreaUnit] = useState("");
  const [fertRate, setFertRate] = useState("");
  const [fertIdValid, setFertIdValid] = useState(true)
  const [fertproductUnitValid, setFertproductUnitValid] = useState(true)
  const [fertAreaUnitValid, setFertAreaUnitValid] = useState(true)
  const [fertRateValid, setFertRateValid] = useState(true)
  

  const handleAddFert = () => {
    if(fertId.length === 0)
    { 
      setFertIdValid(false);
      alert("Please select a fertiliser")
      return;
    }
    if(fertproductUnit.length === 0)
    { 
      setFertproductUnitValid(false);
      alert("Please select a fertiliser measurement unit")
      return;
    }
    if(fertAreaUnit.length === 0)
    { 
      setFertAreaUnitValid(false);
      alert("Please select a fertiliser area unit")
      return;
    }
    if(fertRate.length === 0)
    { 
      setFertRateValid(false);
      alert("Please select a fertiliser rate")
      return;
    }
    props.setFertsAdded([...props.fertsAdded, {
      fertilizerId: fertId,
      name: ferts.find(x => x.id === fertId).name,
      productUnit: fertproductUnit,
      areaUnit: fertAreaUnit,
      rate: fertRate
    }])
    setFertId("");
    setFertproductUnit("");
    setFertAreaUnit("");
    setFertRate("");
  }

  const handleFertId = (val) => {    
    setFertId(val);
    if(val.length === 0)
      setFertIdValid(false);
    else
      setFertIdValid(true);
  }

  const handleFertproductUnit = (val) => {    
    setFertproductUnit(val);
    if(val.length === 0)
      setFertproductUnitValid(false);
    else
    setFertproductUnitValid(true);
  }

  const handleFertAreaUnit = (val) => {    
    setFertAreaUnit(val);
    if(val.length === 0)
      setFertAreaUnitValid(false);
    else
      setFertAreaUnitValid(true);
  }  

  const handleFertRate = (val) => {    
    setFertRate(val);
    if(val.length === 0)
      setFertRateValid(false);
    else
      setFertRateValid(true);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (  
    <Grid item xs={12}>
      <Paper className={classes.paper}>
      { getFertsPending ? 
          (
            <Grid container direction="row" justify="center" alignItems="center">
            <CircularProgress />
            </Grid>
          ) : (<Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>Add Fertilizer</Typography>
            </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="fert-label" error={!fertIdValid}>Fertilizer</InputLabel>
              <Select labelId="fert-label" error={!fertIdValid} value={fertId} onChange={(e) => handleFertId(e.target.value)}>
                {ferts.filter(item => !props.fertsAdded.map(x => x.fertilizerId).includes(item.id)).sort((a,b) => a.name.localeCompare(b.name)).map((row) => (
                  <MenuItem value={row.id}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9} md={10}>
            <FormControl fullWidth>
              <InputLabel id="fert-rate-label" error={!fertproductUnitValid}>Measurement Unit</InputLabel>
              <Select labelId="fert-rate-label" error={!fertproductUnitValid} value={fertproductUnit} onChange={(e) => handleFertproductUnit(e.target.value)}>
                {fertId.length === 0 ?
                  (<MenuItem value="">
                    <em>No Ferilizer Selected</em>
                  </MenuItem>) 
                  : productUnits.filter(item => item.isLiquid === ferts.find(x => x.id === fertId)?.isLiquid).map((row) => (
                  <MenuItem value={row.value}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} md={2}>
              <IconButton onClick={handleClick} aria-label="Not what you expected? Check fertilizer details in inventory.">
                <HelpIcon />
              </IconButton>
              <Popper style={{"maxWidth": "50%"}} id={id} open={open} anchorEl={anchorEl}>
                <Paper  className={classes.paper}>Not what you expected? Check fertilizer details in inventory.</Paper>
              </Popper>
          </Grid>  
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="fert-rate-label" error={!fertAreaUnitValid}>Area Unit</InputLabel>
              <Select labelId="fert-rate-label" error={!fertAreaUnitValid} value={fertAreaUnit} onChange={(e) => handleFertAreaUnit(e.target.value)}>
                {fertId.length === 0 ?
                  (<MenuItem value="">
                    <em>No Ferilizer Selected</em>
                  </MenuItem>) 
                  : areaUnits.map((row) => (
                  <MenuItem value={row.value}>{row.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <TextField fullWidth label="Rate" type="number" InputProps={{
                    endAdornment: <InputAdornment position="end">{(productUnits[fertproductUnit]?.label ?? "") + "/" + (areaUnits[fertAreaUnit]?.label ?? "")}</InputAdornment>,
                  }} error={!fertRateValid} value={fertRate}  onChange={(e) => handleFertRate(e.target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Button disabled={props.submitPending || !fertIdValid || !fertproductUnitValid || !fertAreaUnitValid || !fertRateValid} variant="contained" color="primary" onClick={handleAddFert}>{"Add "}<AddIcon/></Button>
            </Grid>
          </Grid>
        </Grid>)}
      </Paper>
    </Grid>         
  );
}