import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Button, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getAnalytics, logEvent  } from "firebase/analytics";
import { getLocation, updateLocation } from './api/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
import Title from './Title';

const containerStyle = {
  height: '50vh'
};

const libraries = ["places"]

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));



export default function UpdateLocation(props) {
  const analytics = getAnalytics();
  let history = useHistory();
  const classes = useStyles();
  props.setTitle("Update Location");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [zoom, setZoom] = useState(10);
  const [getLocationPending, setGetLocationPending] = useState(true);
  const [submitPending, setSubmitPending] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [autocompletex, setAutocompletex] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try{
        logEvent(analytics, 'location.getLocation.started');
        const result = await getLocation(getAccessTokenSilently);
        setLat(result.data?.lat ?? 42.348861); 
        setLon(result.data?.lon ?? -51.4180472);  
        setZoom(result.data === "" ? 1 : 15);
        logEvent(analytics, 'location.getLocation.success');
      }
      catch{
        logEvent(analytics, 'location.getLocation.failed');
        alert("failed, try again")
      }
      setGetLocationPending(false);
    }; 
    fetchLocation();
  }, [analytics, getAccessTokenSilently])
 
  const handleAdd = async () => {
    setSubmitPending(true);
    try{
      logEvent(analytics, 'location.updateLocation.started');
      await updateLocation(getAccessTokenSilently, {
        lat: lat,
        lon: lon,
      })
      logEvent(analytics, 'location.updateLocation.success');
      history.push("/areas");
    }
    catch{
      logEvent(analytics, 'location.updateLocation.failed');
      alert("error, check the formating of coordinates contains and try again.");
    }
    setSubmitPending(false);
  }

  const onLoad = (autocomplete) => {
    console.log('autocomplete: ', autocomplete)
  
    setAutocompletex(autocomplete)
  }
  
  const onPlaceChanged = () => {
    if (autocompletex !== null) {
      setLat(autocompletex.getPlace().geometry.location.lat)
      setLon(autocompletex.getPlace().geometry.location.lng)
      setZoom(15);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const onDragEnd = (e) => {
    setLat(e.latLng.lat)
    setLon(e.latLng.lng)
  }

  const autoDetect = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
      setZoom(15);
    });
  }

  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
              <Grid container spacing={1}>
              {getLocationPending ?  
                (
                  <Grid item container direction="row" justify="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
                ) : 
                (
                 <LoadScript
                  googleMapsApiKey="AIzaSyBKnZHVBLBWzEH_E_M2voJXdbnkXuh8T1s"
                  libraries={libraries}
                >
                 
                   <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Title>Drag pin to exact location</Title>
                    </Grid>
                    
                    <Grid item xs={12}> 
                    <Button disabled={submitPending} variant="contained" color="primary" onClick={autoDetect}>Auto Detect</Button>
                    </Grid> 
                     <Grid item xs={12}>
                   <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <TextField fullWidth  placeholder={"Search for a location"}/>
                    </Autocomplete>
                    </Grid>
                    <Grid item xs={12}>   
                                     
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{lat:parseFloat(lat),  lng: parseFloat(lon)}}
                    zoom={zoom}
                    
                  >
                     <Marker draggable={true} onDragEnd={onDragEnd}
                      position={{lat:parseFloat(lat),  lng: parseFloat(lon)}}
                    />
                    
                  </GoogleMap>
                  </Grid>
                <Grid item xs={12}>
                  <Typography>Updating location will only effect weather data and GDD from tomorrow. Historic weather data for previous location will remain.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button disabled={submitPending} variant="contained" color="secondary" onClick={history.goBack}>Cancel</Button>
                    <Button disabled={submitPending} variant="contained" color="primary" onClick={handleAdd}>{"Update "}<SaveIcon/></Button>
                  </Grid>
                </Grid>
                  </Grid>
                </LoadScript> )}
                </Grid>
              </Paper>
            </Grid>
            
          </Grid>
        </Container>
  );
}