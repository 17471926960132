import React , { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { getFerts } from './api/api';
import { useAuth0 } from "@auth0/auth0-react";
import { getAnalytics, logEvent  } from "firebase/analytics";
import { productUnits } from './productUnits';

export default function FertsTable() {
  let history = useHistory();
  const [getFertsPending, setGetFertsPending] = useState(true);
  const [ferts, setFerts] = useState([]);
  const analytics = getAnalytics();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {   
    const fetchFerts = async () => {
      try{
        logEvent(analytics, 'fertsTable.fetchFerts.started');
        const result = await getFerts(getAccessTokenSilently)
        setFerts(result.data);
        logEvent(analytics, 'fertsTable.fetchFerts.success');
      }
      catch{
        logEvent(analytics, 'fertsTable.fetchFerts.failed');
        alert("error, try again")
      }
      setGetFertsPending(false);
    };  
    fetchFerts();   
  }, [analytics, getAccessTokenSilently]);

  
  
  return getFertsPending ? 
  (
    <Grid container direction="row" justify="center" alignItems="center">
    <CircularProgress />
    </Grid>
  ) :
  (
    <React.Fragment>
      <Title>Fertilizers</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>N</TableCell>
            <TableCell>P</TableCell>
            <TableCell>K</TableCell>
            <TableCell>Fe</TableCell>
            <TableCell>Mg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ferts.sort((a,b) => a.name.localeCompare(b.name)).map((row) => (
            <TableRow key={row.id} hover="true" onClick={() =>  history.push("/fertilizers/" + row.id)}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.isLiquid ? (row.volume + " " + productUnits[row.volumeUnit]?.label) :  (row.weight + " " + productUnits[row.weightUnit]?.label) }</TableCell>
              <TableCell>{row.nutrients.find(x => x.id === 0)?.percentage ?? 0}</TableCell>
              <TableCell>{row.nutrients.find(x => x.id === 1)?.percentage ?? 0}</TableCell>
              <TableCell>{row.nutrients.find(x => x.id === 2)?.percentage ?? 0}</TableCell>
              <TableCell>{row.nutrients.find(x => x.id === 3)?.percentage ?? 0}</TableCell>
              <TableCell>{row.nutrients.find(x => x.id === 4)?.percentage ?? 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}