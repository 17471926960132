export const nutrients = [
    {
        value: 0,
        label: "Nitrogen (N)"
    },
    {
        value: 1,
        label: "Phosphate (P)"
    },
    {
        value: 2,
        label: "Potassium (K)"
    },
    {
        value: 3,
        label: "Iron (Fe)"
    },
    {
        value: 4,
        label: "Magnesium (Mg)"
    }
]