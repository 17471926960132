import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import { Typography } from '@material-ui/core';
import { getAnalytics, logEvent  } from "firebase/analytics";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Donate(props) {
  const classes = useStyles();
  const analytics = getAnalytics();
  logEvent(analytics, 'donate.viewed');
  props.setTitle("Donate");
  return (    
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
              <Paper className={classes.paper}>
              <Title>Contribute to the LawnTrack community</Title>
              <Typography>
                LawnTrack has a number of 3rd party fees to pay
                <ul>
                  <li>Weather Data</li>
                  <li>Email Notifications</li>
                  <li>Google Maps</li>
                  <li>Hosting</li>
                  <li>Storage</li>
                </ul>
              </Typography>
              <Typography>
                I greatly appreciate any donations be it large or small, one off or regular, to support and keep LawnTrack free for the lawn community into the future.
              </Typography>
              <Typography>
                Liam
              </Typography>
              <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="4Y8HBR9H5GRS2" />
                <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
              </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
  );
}