export const productUnits = [
    {
        value: 0,
        isLiquid: false,
        label: "grams"
    },
    {
        value: 1,
        isLiquid: false,
        label: "oz"
    },   
    {
        value: 2,
        isLiquid: false,
        label: "lbs"
    },
    {
        value:3,
        isLiquid: false,
        label: "kg"
    },
    {
        value: 4,
        isLiquid: true,
        label: "ml"
    },
    {
        value: 5,
        isLiquid: true,
        label: "fluid oz"
    },
    {
        value: 6,
        isLiquid: true,
        label: "pint (US)"
    },
    {
        value: 7,
        isLiquid: true,
        label: "pint (imperial)"
    },
    {
        value: 8,
        isLiquid: true,
        label: "quart (US)"
    },
    {
        value: 9,
        isLiquid: true,
        label: "litre"
    },
    {
        value: 10,
        isLiquid: true,
        label: "quart (imperial)"
    },
    {
        value: 11,
        isLiquid: true,
        label: "gallon (US)"
    },
    {
        value: 12,
        isLiquid: true,
        label: "gallon (imperial)"
    }
]