import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GetAppIcon from '@material-ui/icons/GetApp';
import {  Link} from "react-router-dom";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NotificationsIcon from '@material-ui/icons/Notifications';

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button  component={Link} to="/applications">
      <ListItemIcon>
        <GetAppIcon />
      </ListItemIcon>
      <ListItemText primary="Applications" />
    </ListItem>
    <ListItem button  component={Link} to="/inventory">
      <ListItemIcon>
        <FolderOpenIcon />
      </ListItemIcon>
      <ListItemText primary="Inventory" />
    </ListItem>
    
    <ListItem button  component={Link} to="/areas">
      <ListItemIcon>
        <LocationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Areas" />
    </ListItem>
    
    <ListItem button  component={Link} to="/weather">
      <ListItemIcon>
        <WbSunnyIcon />
      </ListItemIcon>
      <ListItemText primary="Weather" />
    </ListItem>

    <ListItem button  component={Link} to="/notifications">
      <ListItemIcon>
        <NotificationsIcon  />
      </ListItemIcon>
      <ListItemText primary="Notifications" />
    </ListItem>
    
  </div>
);
