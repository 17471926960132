import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { useAuth0 } from "@auth0/auth0-react";
import { clearExpiry } from './api/api';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { getAnalytics, logEvent  } from "firebase/analytics";

export default function ProductAppsTable(props) {
  const analytics = getAnalytics();
  const [clearExpiryPending, setClearExpiryPending] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleClearExpiry = async (appId, productId) => {
    try{      
      logEvent(analytics, 'productAppsTable.handleClearExpiry.started');
      setClearExpiryPending(true);
      await clearExpiry(getAccessTokenSilently, {appId: appId, productId: productId});
      logEvent(analytics, 'productAppsTable.handleClearExpiry.success');
      props.setExpiringProducts(props.data.filter(item => !(item.appId === appId && item.productId === productId)));
      setClearExpiryPending(false);
    }
    catch{
      logEvent(analytics, 'productAppsTable.handleClearExpiry.failed');
      alert("error, try again")
    }
    setClearExpiryPending(false);
  }

  return (
    <React.Fragment>
      <Title>Expiring Applications</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Area</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>Interval</TableCell>
            <TableCell>Percent</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.sort((a, b) => a.end == null ? 0 : moment(a.end) - moment(b.end)).map((row) => (
            <TableRow key={row.appId+":"+row.productId}>
              <TableCell>{row.areaName}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{moment(row.start).format('MMM Do YY')}</TableCell>
              <TableCell>{row.end == null ? "" : moment(row.end).format('MMM Do YY')}</TableCell>
              <TableCell>{row.intervalElapsed + "/" + row.intervalTotal + " " + (row.gdd ? "gdd" : "days")}</TableCell>
              <TableCell>{parseInt(row.percent)}</TableCell>
              <TableCell align="right"><Button variant="contained" color="primary" disabled={clearExpiryPending} onClick={() => handleClearExpiry(row.appId, row.productId)}>Clear</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}